import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/ONVIF/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ONVIF",
  "path": "/Quick_Installation/ONVIF/",
  "dateChanged": "2020-03-17",
  "author": "Mike Polinowski",
  "excerpt": "The ONVIF Service",
  "image": "./QI_SearchThumb_ONVIF.png",
  "social": "/images/Search/QI_SearchThumb_ONVIF.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-ONVIF_white.webp",
  "chapter": "Installation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ONVIF' dateChanged='2020-03-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='The ONVIF Service' image='/images/Search/QI_SearchThumb_ONVIF.png' twitter='/images/Search/QI_SearchThumb_ONVIF.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/ONVIF/' locationFR='/fr/Quick_Installation/ONVIF/' crumbLabel="ONVIF" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-onvif-protocol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-onvif-protocol",
        "aria-label": "the onvif protocol permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The ONVIF Protocol`}</h1>
    <h2 {...{
      "id": "all-current-instar-1080p-ip-cameras-are-compliant-with-the-onvif-profile-s-version-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#all-current-instar-1080p-ip-cameras-are-compliant-with-the-onvif-profile-s-version-24",
        "aria-label": "all current instar 1080p ip cameras are compliant with the onvif profile s version 24 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All current INSTAR 1080p IP cameras are compliant with the ONVIF Profile S Version 2.4`}</h2>
    <p><em parentName="p">{`The 1440p, 1080p annd 720p Series support the ONVIF Profile S Version 2.4`}</em></p>
    {/*
     * _Die 1440p Serie unterstützt das ONVIF Profil T Version 2.4_
     * _Die 1080p/720p Serie unterstützt mit der neuesten Firmware Version das ONVIF Profil S Version 2.4_
     */}
    <Link to="/Quick_Installation/ONVIF/Software_Integration/" mdxType="Link"><EuiButton fill style={{
        float: 'right'
      }} mdxType="EuiButton">Software Integration</EuiButton></Link>
    <p>{`The surveillance industry has created an open standard protocol CALLED ONVIF that allows cameras to communicate with each other and with network recording devices. ONVIF has different versions of compatibility that dictate which features can be integrated into the system. In some cases, a camera can have basic video compatibility but lack the ability to do more advanced features, such as motion detection. All our 1440p IP product are 100% ONVIF compatible with version 2.4 of Profile S for IP security cameras.`}</p>
    <p>{`ONVIF does no policing of its standards. Sometimes companies say that a device is ONVIF compliant when it is not.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75ef3e81a8538e8009381ed4f393a718/00d43/ONVIF_Device_Manager_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKUlEQVQoz2PY9Oj78de/Tr77e/rDv5Pv/h19/evoq19HX/08/vrX8de/jr2CcKHo+Ns/2y4/mXX00YzLH3Y++c5w7sbrG+fPnzp84MrZ06ePHPz69vXvf/+//P738c//n//+o4B/IP7vly8e33959/WXr7/+MBw9dvvO3XtXrl0/f/HS1atX37x99/L737uf/9598+num0/P3n/5/PnLx0+fv3379vnLl3fvP3z+9u3rty9PHz749PETg7GFsYWFmb2NuZuznYODrUVAqP2mB45rr7v6eHu4O5tZWTi5ODk42lvb2xuamOob6mvp6JiZmyoqK7W2tzHoubuo6unoygq6m6iIaemqOdrbJKbGlpW0dzZ0dzfYhYS0tDX0T+oNSc/1DI/IK8gwd3RcMHdqYmZCTUcbQ3ROhl1gCLOwNLeMMpeqrqyFpZGHR1p2Wk1deVdfp0dCcn5NTVN3d3ljc0RmTnRmRmhcZGVVoZ23a0ljE4OTp7O0hia/uCwvvwinpKytn294fFxJZUVOaVlKTnZiSmJObnZWZlpySpJ/VFRIeqaqs4ehT4COo1NhXSODg5O9vYujoaWVnKIKO6+AsramlbWFrZ2NlbWlla2Vo5ODvYODmbW1mqGhhLaeubuXpJaupK4hq7RSZnEFQ2BgYGpKcnRsjLuvn4a2vpK2roefv4ePj4KGlqC0jJyyqrKmprSaurqxiYS6JgMXHwMHLzM3PwMDQ25OHgBh6wf6lSerNgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75ef3e81a8538e8009381ed4f393a718/e4706/ONVIF_Device_Manager_00.avif 230w", "/en/static/75ef3e81a8538e8009381ed4f393a718/d1af7/ONVIF_Device_Manager_00.avif 460w", "/en/static/75ef3e81a8538e8009381ed4f393a718/7f308/ONVIF_Device_Manager_00.avif 920w", "/en/static/75ef3e81a8538e8009381ed4f393a718/445fc/ONVIF_Device_Manager_00.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75ef3e81a8538e8009381ed4f393a718/a0b58/ONVIF_Device_Manager_00.webp 230w", "/en/static/75ef3e81a8538e8009381ed4f393a718/bc10c/ONVIF_Device_Manager_00.webp 460w", "/en/static/75ef3e81a8538e8009381ed4f393a718/966d8/ONVIF_Device_Manager_00.webp 920w", "/en/static/75ef3e81a8538e8009381ed4f393a718/a5d4d/ONVIF_Device_Manager_00.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75ef3e81a8538e8009381ed4f393a718/81c8e/ONVIF_Device_Manager_00.png 230w", "/en/static/75ef3e81a8538e8009381ed4f393a718/08a84/ONVIF_Device_Manager_00.png 460w", "/en/static/75ef3e81a8538e8009381ed4f393a718/c0255/ONVIF_Device_Manager_00.png 920w", "/en/static/75ef3e81a8538e8009381ed4f393a718/00d43/ONVIF_Device_Manager_00.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75ef3e81a8538e8009381ed4f393a718/c0255/ONVIF_Device_Manager_00.png",
              "alt": "ONVIF Device Manager",
              "title": "ONVIF Device Manager",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "how-to-check-your-onvif-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-check-your-onvif-version",
        "aria-label": "how to check your onvif version permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to check your ONVIF version?`}</h2>
    <p>{`If you want to use your INSTAR camera with an ONVIF recording device (NVR, NAS, etc.), you will need to contact the manufacturer or retailer of the device and ask them what version of ONVIF they support. If they do not give you a version number and instead just say `}<em parentName="p">{`Profile S`}</em>{` or `}<em parentName="p">{`ONVIF compliance`}</em>{` with no version number, they have dodged your question. You can check the ONVIF Version of your IP camera with a Windows tool called `}<strong parentName="p">{`ONVIF Device Manager`}</strong>{`. You can download it from `}<a parentName="p" {...{
        "href": "http://sourceforge.net/projects/onvifdm/"
      }}>{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d41e40254e840852c4e8a091f17c7fa/00d43/ONVIF_Device_Manager_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/klEQVQoz3XRzXLTMBQFYD8QfQV2TMtzwIYFLwI7Bl6CNdPSKS2Faab5dWw3bjBNHf9Kli3JsiXZka0mUAYWheGbsz33Lo5x6/thGK7X69VqRfLcW7i2aS4syzHN37Es5z8Mxsq6qoqiYBijw4/+8cnt4VF0epZfDNDgEp6cksthJQQrS/YPA5R1QqqkoH5GbiaWP7VXExtMZ3A6AzMTzy0FM9X3Xdep7m/GMqiWXvDp7OzicjhfuKZtLzzPXV6Pp5PRZDybm5PpVPB6t9ttt9vdQ0YQsDSBy2s3CgI/iKyb4NqPbO/WuQndVTi68r7HWSFVLlTCGq70g3J3pxvVcSGapqm44G1XN61oVSVb3m5quRGbjlQ1qTmpBZfNnb7X615rbawzEuaUEoxxEcdxniOQphCAHGUYF0kcMVYikGFYyFJUpFKd7je6b3vOudFKWQkJmSgZk1JyzhljhNwfQwihHFFCQxS6ieskjhvZnm9fRfYy+yYbaZSUhgg7cW77iZ9AlEH8C0IIABAnMUHlqw+vn745ePz2ycvne+/39569eLT/7gAzbAghKKUoRynMUgABAISQoiiyLEvTBELkLYZjZ/B5fj64Goztwfnw69Hoy7E9ElIafd9vt1uttVKbRjacc0rpn88pgChce4wS1aiu7fr+bqN3+tdkP3f+AVP5SPgKN1fBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d41e40254e840852c4e8a091f17c7fa/e4706/ONVIF_Device_Manager_01.avif 230w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/d1af7/ONVIF_Device_Manager_01.avif 460w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/7f308/ONVIF_Device_Manager_01.avif 920w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/445fc/ONVIF_Device_Manager_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d41e40254e840852c4e8a091f17c7fa/a0b58/ONVIF_Device_Manager_01.webp 230w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/bc10c/ONVIF_Device_Manager_01.webp 460w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/966d8/ONVIF_Device_Manager_01.webp 920w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/a5d4d/ONVIF_Device_Manager_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d41e40254e840852c4e8a091f17c7fa/81c8e/ONVIF_Device_Manager_01.png 230w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/08a84/ONVIF_Device_Manager_01.png 460w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/c0255/ONVIF_Device_Manager_01.png 920w", "/en/static/3d41e40254e840852c4e8a091f17c7fa/00d43/ONVIF_Device_Manager_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d41e40254e840852c4e8a091f17c7fa/c0255/ONVIF_Device_Manager_01.png",
              "alt": "ONVIF Device Manager",
              "title": "ONVIF Device Manager",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "the-onvif-device-manager",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-onvif-device-manager",
        "aria-label": "the onvif device manager permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The ONVIF Device Manager`}</h3>
    <p>{`Start the ONVIF Device Manager and type in your camera login. Select your camera from the list of discovered ONVIF devices inside your local network.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/56cce787cca854256b5024e515c9a35f/00d43/ONVIF_Device_Manager_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABLElEQVQoz6XQa0vCUADG8X3fMEoR54JSWZeZkbWlbYVMDLt4yZhMs7RzTq7cObLpejXBF0VCfQadp09QOYT/2x8PPAzRWqNXbD2AkWm70MBt1DKsBjB/Swf4Hrx04FPl0WSym3wuzqfDXCoU2QnHuI2DQEIKbB3/0WrsaD2eXolJTL1w2YfAghDVavup01DynBOUaPKfWOEsmlSY4qHY0vUeQk63e5KSg7syuydHFou5VgtI0/rtjg2AKF8Et7OssDAulqrwGfVte/jmiGopyGdYQVkUX5VuIQCEkOFgIOZu/OIqghBjbFuWb3xXbxKCHccZuW4mX/GH3z+/KKXefE4pVcuNtYQU9YEny+Bllj8m35TSmedRSvOVpj9s9Ig3m06ns/F4LKllX4f9ABIS+RuJ1KrcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56cce787cca854256b5024e515c9a35f/e4706/ONVIF_Device_Manager_02.avif 230w", "/en/static/56cce787cca854256b5024e515c9a35f/d1af7/ONVIF_Device_Manager_02.avif 460w", "/en/static/56cce787cca854256b5024e515c9a35f/7f308/ONVIF_Device_Manager_02.avif 920w", "/en/static/56cce787cca854256b5024e515c9a35f/445fc/ONVIF_Device_Manager_02.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/56cce787cca854256b5024e515c9a35f/a0b58/ONVIF_Device_Manager_02.webp 230w", "/en/static/56cce787cca854256b5024e515c9a35f/bc10c/ONVIF_Device_Manager_02.webp 460w", "/en/static/56cce787cca854256b5024e515c9a35f/966d8/ONVIF_Device_Manager_02.webp 920w", "/en/static/56cce787cca854256b5024e515c9a35f/a5d4d/ONVIF_Device_Manager_02.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56cce787cca854256b5024e515c9a35f/81c8e/ONVIF_Device_Manager_02.png 230w", "/en/static/56cce787cca854256b5024e515c9a35f/08a84/ONVIF_Device_Manager_02.png 460w", "/en/static/56cce787cca854256b5024e515c9a35f/c0255/ONVIF_Device_Manager_02.png 920w", "/en/static/56cce787cca854256b5024e515c9a35f/00d43/ONVIF_Device_Manager_02.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/56cce787cca854256b5024e515c9a35f/c0255/ONVIF_Device_Manager_02.png",
              "alt": "ONVIF Device Manager",
              "title": "ONVIF Device Manager",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Identification and check your camera´s ONVIF Version. Make sure that all your ONVIF devices support the same standard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/00d43/ONVIF_Device_Manager_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACR0lEQVQoz5WRX0/aYBjF+63ch3BfYN7PP5vbUKfTadg0UbNMI7tassiVw2URTTYMFCZjCLRIqVSESqEFCy1tzaBQKBRK32V2OtncxU7OzXPe55fzJC9kWVgdn12asy5PzK+MTS/en7SOTlofWhbGni4+fr7yYGZpdOrFyA0PT718Mj07PmMdnnsNwR636/Mnu93u2N4+TZ7E8Hj4w05w9U1gd/coHo8ikYMvvnA4FLkyiiARBDlC0Y97LghBEIrKMgzDFIpyTa3VKtWKnkkBUVQbDaXVanElrlqtqmqr0Wy2220AgGEYAAAinYPW1tZsG7b19XWv76DbBaqqVCo6TQNBrEmiKEkiQRBkmqQyFEmSLMsahqHrOgAgRpDQ0NC9wcG7dwYGLJYJh+P95uY7m+3t1tYOfhwXpIuqLKdIks7nmUIhyzC8IFw3YydnkNPpdLlcMAz7/F8DwcPAt8D+vsfvRxI4wVOUSDOnCErheD6ZzCUSfDbbB8MwjGFYMp2O+v2v5udsGxvn598zFMinOCF8yAbRtNuX8/po30HW4+VDkT7Y7fFEURQ7TgT2nMuPRhaeTSSxlCyDaq2udbo9YLQ0TdP1iixjOJ5jmD6YYWiWZUulUlm8+JldvdWVRrfbBbfpN3w9KEpD07Tepf6GjUvdAvd6PcMw6nXF/IP/azaralfbv8a60ul0rjtvylyIETfOVlXVTM2x0Wyah/yrGT/NQCRFFwUJOYoHQmipLBV5keUEriyd5QqFIm8mf5jlBF688AZjPwArsswKnJVgRwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/e4706/ONVIF_Device_Manager_03.avif 230w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/d1af7/ONVIF_Device_Manager_03.avif 460w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/7f308/ONVIF_Device_Manager_03.avif 920w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/445fc/ONVIF_Device_Manager_03.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/a0b58/ONVIF_Device_Manager_03.webp 230w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/bc10c/ONVIF_Device_Manager_03.webp 460w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/966d8/ONVIF_Device_Manager_03.webp 920w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/a5d4d/ONVIF_Device_Manager_03.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/81c8e/ONVIF_Device_Manager_03.png 230w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/08a84/ONVIF_Device_Manager_03.png 460w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/c0255/ONVIF_Device_Manager_03.png 920w", "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/00d43/ONVIF_Device_Manager_03.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b94cc3fe9a7163ae1cc179bdb1cbe5c1/c0255/ONVIF_Device_Manager_03.png",
              "alt": "ONVIF Device Manager",
              "title": "ONVIF Device Manager",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Profile S is designed for IP-based video systems. A Profile S device (e.g., an IP network camera or video encoder) is one that can send video data over an IP network to a Profile S client. A Profile S client (e.g., a video management software) is one that can configure, request, and control video streaming over an IP network from a Profile S device. Profile S also covers ONVIF specifications for PTZ control, audio in, multicasting and relay outputs for conformant devices and clients that support such features.`}</p>
    <p>{`The supported ONVIF version dictates the available functions. Be aware that, due to this restriction, the amount of functions can be reduced if your INSTAR camera is not supported natively by your recording device.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      